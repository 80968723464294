.val-success{
    color:green;
}

.val-fail{
    color:red;
}

.rmdp-wrapper  {
    width: 100% !important; 
}

.rmdp-top-class, .rmdp-day-picker  {
    display: block !important;
}

#cardAddBtn iframe {
    height: 40px !important;
}
