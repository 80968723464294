$themeBackgroundColor: #23286b;
$themeSecondaryBackgroundColor: #a4c772;
$libreFranklin: "Libre Franklin";
$errorColor: #b00120;
$fontColor9a9a9a: #9a9a9a;
$fontSize36pxVw:  1.875vw;
$fontSize22pxVw:  1.1458vw;
$fontSize20pxVw: 1.04166vw;
$fontSize18pxVw: 0.9375vw;
$fontSize16pxVw: 0.8333333vw;
$fontSize14pxVw: 0.729vw;
$fontColorBlack: #000000;
$HelveticaNeue: "Libre Franklin";


