@import '../../../utils//StylesUtils/variables.scss';

.updatePasswordText{
    width: 458px;
    height: 21px;
    font-family: $libreFranklin;
    font-size: 18px;
    text-align: left;
    margin-top: 72px;
    margin-bottom: 20px;
    color: #707070;
}