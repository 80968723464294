.card {
    width: 19%;
    height: 300px;
    perspective: 1000px;
    margin: 10px 0;
    position: relative;

    &.flipped .card-front {
        transform: rotateY(180deg);
    }
    &.flipped .card-back {
        transform: rotateY(0);
    }
    &:hover {
        transform: scale(1.05); /* Slightly scale up the card on hover */
        box-shadow: 8px 16px 32px rgba(0, 0, 0, 0.3); /* Enhance the shadow on hover */
    }
    .card-front, .card-back {
        width: 100%;
        height: 100%;
        transition: transform 0.6s;
        transform-style: preserve-3d;
        backface-visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        border: double 1px transparent;
        border-radius: 10px;
        border-image: linear-gradient(to right, #009FF5, #0056b3) 1; /* Add a gradient border */
        background-image: linear-gradient(#fff, #fff), radial-gradient(circle at top left, #009FF5, #0056b3);
        background-origin: border-box;
        background-clip: padding-box, border-box;
        background-color: #efeeeb4D; //fff;
        padding: 20px;
        box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.2); /* Add shadow to the cards */
        i {
            position: absolute;
            top: 40px; /* Adjust this value to position the icon from the top */
            font-size: 36px;
        }
        p {
            margin: 120px 0 0 0; /* Adjust top margin to accommodate the icon */
            font-size: 18px;
            text-align: center; /* Ensure text is centered */
            width: 100%;
            color: #000; //font color
        }
        ul {
            list-style-type: disc;
            padding-left: 20px;
            text-align: left;
        }
        li {
            font-size: 16px;
            margin-bottom: 5px;
        }
    }
    .card-front {
        transform: rotateY(0);
    }
    .card-back {
        transform: rotateY(180deg);
        position: absolute;
        top: 0;
        left: 0;
    }
}
@media (max-width: 767px) {
    .card {
        width: 80%; // Adjust the width for mobile view
        margin: 10px auto; // Center the card
    }
}

.custom-stepper {
    .MuiMobileStepper-dot {
        background-color: #d3d3d3; // Default dot color
        width: 10px; // Adjust the width of the dots
        height: 10px; // Adjust the height of the dots
        border-radius: 50%; // Ensure the dots remain circular
    }

    .MuiMobileStepper-dotActive {
        background-color: #009FF5; // Active dot color
        width: 12px; // Adjust the width of the active dots
        height: 12px; // Adjust the height of the active dots
        border-radius: 50%; // Ensure the dots remain circular
    }
}

