@import '../../../utils//StylesUtils/variables.scss';

.registerThankyouMessage{
    height: 60px;
    font-family: $libreFranklin;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.55;
    letter-spacing: 0.44px;
    text-align: center;
    color: #333333;
    padding-top: 128.5px;
}