@import "../../utils//StylesUtils/variables.scss";

.multipleInviteTableHeadings{
    text-align: center;
    margin-top: 2vw;
    border-bottom: 2px solid #c7c7c7;
    margin-left: 12.5vw;
    margin-right: 12.5vw;
}


.multipleInviteTableContent{
    width: 46vw;
    padding-left: 2vw;
    margin-left: 10vw;
    height: 22vw;
    position: absolute;
    overflow-x: auto;
}

.multipleInviteRemoveIcon{
    color: #c4c4c4
}

.multipleInviteRemoveIcon:active {
    color: red;   
}

.multipleInviteRemoveIcon:hover {
    color: #b00120;   
}

.multipleInviteeHeading{
    font-family: $libreFranklin;
    font-size: 2.5vw;
    font-weight: 600;
    line-height: 0.67;
    text-align: left;
    color: #707070;
    position: absolute;
    margin-left: 25vw;
    margin-top: 4vw;
}

.nonPayableTimeLabelForModel {
    font-family: $libreFranklin;
    font-size: 18px;
    color: #707070;
    margin-bottom: 12px;
}
.profilesInviteMessage{
    position: absolute;
    width: 100%;
    text-align: center;
    color: green;
    font-family: 'Libre Franklin';
    margin-top: 0.25vw
}

.singleInviteScheduleLabelForModel {
    font-family: "Libre Franklin";
    margin-left: 3vw;
    margin-top: 26px;
    font-size: 15px;
    font-weight: 600;
    color: #707070;
    margin-bottom: 8px;
}