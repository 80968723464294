@import "../../../utils//StylesUtils/variables.scss";

@mixin wideCardListHeadings {
    font-family: $libreFranklin;
    font-size: $fontSize20pxVw;
    font-weight: bold;
    color: $fontColorBlack;
    display: flex;
  }

.advancedSearchHeading {
    font-family: $libreFranklin;
    font-size: $fontSize36pxVw;
    font-weight: 600;
    line-height: 0.67;
    color: #707070;
    margin-top: 6.5vw;
    margin-left: 5.416vw;
}

.specialityDropdownLabel {
    font-family: $libreFranklin;
    font-size: $fontSize18pxVw;
    font-weight: 600;
    color: #1c1c1c;
    margin-left: 1.2vw;
}

.arrowDropdownIcon {
    position: absolute;
    margin-left: 16vw;
    margin-top: -0.2vw;
    font-size: 1.25vw;
}
.displayDynamicRateStyles {
    font-family: $libreFranklin;
    font-size: $fontSize18pxVw;
    font-weight: 600;
    line-height: 0.83;
    letter-spacing: 0.72px;
    color: #656363;
    margin-left: 0.5vw;
}

.fieldOfWorkSelected {
    font-family: $libreFranklin;
    font-size: $fontSize22pxVw;
    text-align: left;
    color: #707070;
    margin-left: 5.41vw;
}

.fieldOfWorkHeading {
    font-family: $libreFranklin;
    font-size: $fontSize22pxVw;
    font-weight: 600;
    color: #1c1c1c;
    margin-left: 5.41vw;
    margin-top: 3.489vw;
}

.worldWide{
    border-radius: $fontSize16pxVw;
    font-size: $fontSize14pxVw;
    line-height: 1.43;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.87);
    background-color: #f2f2f2;
    margin-left: 5.41vw;
    padding: 6px 35px 7px 12px;
    width: 1.5vw;
    margin-top: 1.61vw;
}

.maps{
    width: 70%;
    height: 17.86vw;
    background-color: #f2f2f2;
    border-radius: 20px;
    margin: auto;
}

.hourlyRateSlider{
    width: 70%;
    margin: auto;
}

.ratingSlider{
    width: 70%;
    margin: auto;
}

.experienceSlider{
    width: 70%;
    margin: auto;
}

.topProfileSearchBar{
    width: 50vw;
    /* margin-top: 6.5vw; */
    /* margin-left: 4.01vw; */
    height: 4.79vw;
    border-radius:  $fontSize20pxVw;
    background-color: #f2f2f2;
}

.wideCardHeading{

    display: inline-flex;
    margin-bottom: 1vw;

    .employeeNameHeading{
        @include wideCardListHeadings;
    }
    .experianceHeading{
        @include wideCardListHeadings;
    }
    .scoreHeading{
        @include wideCardListHeadings;
    }
    .hourlyRateHeading{
        @include wideCardListHeadings;
    }
}

.overlayImageStyle{
    position: relative;
    top: 6vw;
    left: 65%;
    transform: translate(-50%, -50%);
  }

  #overlay {
    position: fixed;
    display: block;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    cursor: pointer;
  }

  .specifyDatesCardsPage{
    display: inline-block;
    // position: absolute;
    height: 4.791vw;
    width: 12vw;
    border-right: 1px solid #c4c4c4;
  }

  .sortByStyles{
    font-family: $libreFranklin;
    font-size: 20px;
    text-align: left;
    color: #1c1c1c;
    padding-top: 0.8vw;
    padding-right: 1vw;
  }

  .advancedSearchPagination .MuiPagination-ul {
    justify-content: center;
    margin-bottom: 60px;
    margin-top: 30px;
  }
