@import "../../utils/StylesUtils/variables.scss";

.forgotPasswordCardContentHeading {
  font-family: $libreFranklin;
  font-size: 30px;
  font-weight: 500;
  color: #000000;
  display: inline-block;
  margin-top: 46px;
}

.forgotPasswordContentSubtext {
  width: 454px;
  font-family: "Libre Franklin";
  font-size: 18px;
  color: #707070;
  display: inline-block;
  margin-top: 47px;
}
