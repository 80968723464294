@import "../../utils/StylesUtils/variables.scss";


.SwitchButtonWrapper{
    border: solid 2px #c7c7c7;
    text-align: center;
}

.SwitchTableCell{
    display: table-cell;
    vertical-align: middle;
    font-family: $libreFranklin;
    font-size: 18px;
    color: #9a9a9a;
}