.pricing-container {
    text-align: center;
    padding: 20px;
    h1 {
        margin-top: 100px;
        margin-bottom: 50px;
        font-size: 2em;
        font-weight: bold;
        display: block; // Ensure the element is displayed as a block element
        visibility: visible; // Ensure the element is visible
        z-index: 1; // Ensure it is on top of other elements if needed
    }
}

.pricing-sections {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 50px;
    flex-wrap: wrap; // Allow boxes to wrap on smaller screens
}

.pricing-box {
    border: 1px solid #0b5ed7;//#009ff5; 
    border-radius: 15px;
    padding: 20px;
    margin: 20px;
    width: 100%;
    max-width: 450px;
    height: auto;  
    box-sizing: border-box;

    h2 {
        margin-top: 20px;
        margin-bottom: 40px; // Adjusted for more space between the title and description
        font-size: 1.5em; // Adjust font size for mobile
    }

    p {
        margin-bottom: 10px;
        text-align: left;
        font-size: 1em; // Adjust font size for mobile
    }
}    
// Media query for tablets and smaller screens
@media (max-width: 768px) {
    .pricing-container {
        padding: 10px;
        padding-top: 80px;
        h1 {
            font-size: 1.5em;
            margin-top: 50px;
            margin-bottom: 30px;
        }
    }
    .pricing-sections {
        flex-direction: column; // Stack boxes vertically
        align-items: center; // Center-align the boxes
    }
    .pricing-box {
        padding: 15px;
        margin: 10px 0;
        width: calc(100% - 40px); // Adjust width for padding
        h2 {
            font-size: 1.2em;
            margin-bottom: 20px;
        }
        p {
            font-size: 0.9em;
        }
    }
}

// Media query for mobile screens
@media (max-width: 480px) {
    .pricing-container {
        padding: 5px;
        padding-top: 80px;
        h1 {
            font-size: 1.2em;
            margin-top: 30px;
            margin-bottom: 20px;
        }
    }
    .pricing-sections {
        flex-direction: column; // Stack boxes vertically
        align-items: center; // Center-align the boxes
    }

    .pricing-box {
        padding: 10px;
        margin: 10px 0;
        width: calc(100% - 20px); // Adjust width for padding
        h2 {
            font-size: 1em;
            margin-bottom: 15px;
        }
        p {
            font-size: 0.8em;
        }
    }
}