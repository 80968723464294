p,
h1,
span {
    font-family: 'Libre Franklin',
}

.value {
    font-weight: 700;
}

.tag {
    background-color: #1876D2;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: 'Libre Franklin';
    font-weight: 500;
    margin-right: 7px;
}

.border {
    border: 2px solid #9a9a9a;
    margin-top: 30px;
}

.section {
    padding: 30px;
    display: flex;
    flex-direction: column;

    img {
        width: 35px;
        height: 35px;
        object-fit: contain;
    }
}

.card-override {
    .MuiPaper-root {
        overflow: visible !important;
    }
}

.textField {
    .MuiOutlinedInput-root {
        height: 40px;
        border-radius: 10px;
    }
}

.autocomplete {
    .MuiOutlinedInput-root {
        height: 40px;
        border-radius: 10px;
    }

    .MuiFormControl-root {
        margin: 0;
    }

    .MuiInputBase-input {
        height: 40px;
        align-content: center;
    }

    input {
        height: 4px !important;
    }
}

.monthYearPicker {
    input {
        height: 3px;
    }
}

.invoice-tabel {
    border-collapse: collapse;
    font-family: "Libre Franklin";
    width: 100%;
}

.invoice-tabel-tr {
    border-bottom: 2px solid #C4C4C4;
    height: 60px;
    text-align: left;
}

.invoice-tabel-td {
    width: 16%;
    color: #1c1c1c;
    font-size: 16px;
}