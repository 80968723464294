@import "../../utils//StylesUtils/variables.scss";

.defaultStepperLabelStyles {
    font-family: $libreFranklin;
    font-size: $fontSize20pxVw;
    line-height: 0.75;
    letter-spacing: 0.8px;
    color: #9a9a9a;
    margin-top: 2.16vw;
    height: 3.49vw;
    cursor: pointer;

    .defaultStepperInternalHeading {
        margin-left: 40px;
        position: relative;
        top: 50%;
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        display: flex;
        align-items: center;
    }

    .StepperHeader {
        margin-left: 10px;
    }
}

.editDetails{
    display: block;
    .editDetailsContentHeading{
        font-family: $libreFranklin;
        font-size: 36px;
        line-height: 0.81;
        text-align: left;
        color: #070707;
    }

}

.paragraph {
    font-size: 16px;
    font-weight: 500;
}

.editDetailsCompContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}