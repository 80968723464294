@import '../../utils/StylesUtils/variables.scss';

.smallCardScoreBoard {
    width: 3.4375vw;
    height: 4.444vh;
    opacity: 0.5;
    border-radius: 0.9259vh;
    background-color: #ebebeb;
    margin-top: 2.68518vh;
    margin-left: 1.40625vw;
    display: inline-block;
    
    .smallCardScore{
        font-family: $libreFranklin;
        font-size: 0.78125vw;
        // padding-left: 0.78125vw;
        padding-top: 1.388vh;
        text-align: center;
        color: #1c1c1c;
    }
}

.star{
    content: "\2605";
    font-size: 1.0416vw;
    color: #1876d2;
    position: absolute;
    display: inline-block;
    margin-left: 0.35vw;
  }

.smallCardStarRating{
    width: 3.4375vw;
    height: 4.444vh;
    opacity: 0.5;
    border-radius: 0.9259vh;
    background-color: #ebebeb;
    float: right;
    margin-top: 2.68518vh;
    margin-right: 1.40625vw;
    display: inline-block;
}

.starRatingNumber{
    font-family: "Libre Franklin";
    font-size: 0.78125vw;
    margin-top: 0.45vw;
    margin-left: 1.38vw;
    position: absolute;
}

.numberOfStarRating{
    font-family: $libreFranklin;
    font-size: 0.7291vw;
    margin-top: 2.4vh;
    margin-left: 0.8vw;
    position: absolute;
}

.smallCardName{
    opacity: 0.78;
    font-family: $libreFranklin;
    font-size: 0.9375vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: normal;
    text-align: left;
    color: #1c1c1c;
}

.smallCardLeftHeading{
    font-family: $libreFranklin;
    font-size: 0.78125vw;
    line-height: 1.53;
    letter-spacing: normal;
    text-align: left;
    color: #9a9a9a;
    display: inline-block;
    width: 13.5vw;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1vw;
    white-space: nowrap;
}

.smallCardRightHeading{
    font-family: "Libre Franklin";
    font-size: 0.78125vw;
    line-height: 1.6;
    text-align: right;
    color: #1c1c1c;
    // margin-left: 9.895vw;
    display: inline-block;
}

.shortProfileCardImage{
    width: 100%;
    height: 7.2395vw;
    width: 7.2395vw;
    border-radius: 50%;
    margin-left: 5.625vw;
}

.shortCardHeartStyle{
    display: inline-flex;
    margin-top: 5.2vw;
    position: absolute;
    margin-left: -1.4vw;
    border-radius: 50%;
    height: 1.45833vw;
    width: 1.45833vw;
    background-color: white;
}