@import "../../utils//StylesUtils/variables.scss";

.hiringStepperLabelStyles {
    font-family: $libreFranklin;
    font-size: 20px;
    line-height: 0.75;
    letter-spacing: 0.8px;
    color: #9a9a9a;
    height: 3.49vw;
    cursor: pointer;

    .hiringStepperInternalHeading {
        //text-align: center;
        margin-left: 70px;
        position: relative;
        top: 50%;
        font-size: 20px;
        font-weight: 500 !important;
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

.hiringHeader {
    margin-left: 50px;
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 22px;
    font-weight: 600;
}

.editDetailsContentHeading{
    font-family: $libreFranklin;
    font-size: 36px;
    line-height: 0.81;
    text-align: left;
    color: #070707;
}

.blueDropdown{
    font-size: 20px;
}

.blueDropdown:hover{
    text-decoration: none;
    background-color: #1876D2 !important;
    border-radius: 9px;
    color: white;
    font-size: 20px;
}

.review-table-row:last-child{
    border-bottom: none !important;
}

.time-sheet-week{
    background: #EBEBEB;
    width: 240px;
    font-size: 18px;
    border-radius: 8px;
    display: block;
    line-height: 36px;
}

.hiringEmployeeName {
    margin: 0; 
    font-size: 18px; 
    font-weight: 600;
}

.SortByDropdown {
    margin-right: 9px; 
    font-size:18px; 
}

.timeSheetInstrction {
    color: #1876D2;
    font-size: 20px; 
    margin-top: 24px;
}

.fixedFeeEmployeeName {
    margin: 0;
    font-size: 20px; 
    opacity: 0.78;
}

.fixedFeeEmployeeDetails {
    color: #9A9A9A; 
    margin-top: 12px;
}

.timeSheetReviewScreenTop {
    margin-top: 32px; 
    font-size: 20px;
}

.timeSheetReviewScreen {
    margin-top: 20px; 
    font-size: 20px;
}