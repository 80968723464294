.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 1000;
    padding-right: 40px; /* Add padding to push the modal a bit to the left */
  }
  
  .modal-content {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    max-width: 300px;
    width: 100%;
    position: relative; /* Position relative to contain the close button */
  }
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #000;
    color: #007bff;
  }

  .modal-button {
    background-color: #a4c772;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    margin-top: 20px;
  }
  