@import "../../utils//StylesUtils/variables.scss";

.fileDropLabel{
    font-family: $HelveticaNeue;
    font-size: $fontSize20pxVw;
    text-align: center;
    color: #707070;
    margin-top: 1.5vw;
}

.dropboxWrapper{
    width: 62.39vw;
    height: 7.0vw;
    background-color: #f7f7f7;
    border: 1px dotted #c7c7c7;
    border-radius: 0.5vw;
    margin-left: 3vw;
}

.dropboxWrapperElse{
    height: 7.0vw;
    background-color: #f7f7f7;
    border: 1px dotted #c7c7c7;
    border-radius: 0.5vw;
}

.fileDropChipsWrapper{
    margin-top: -1.4vw;
    // position: absolute;
    display: flex;
}