.signUpTopHeading{
    font-family: 'Libre Franklin';
    font-size: 38px;
    font-weight: bold;
    margin: 0px;
    padding-top: 118px;
    padding-bottom: 116px;
}

.microsoftButtonStyles{
    width: '100%';
}

.microsoft {
    background-color: #f25022;
    box-shadow: 8.8px 0 0 0 #7fba00, 0 8.8px 0 0 #00a4ef, 8.8px 8.8px 0 0 #ffb900;
    height: 8px;
    width: 8px;
    margin-top: 20px;
    margin-left: 12px;
}

.microsoftButtonText{
    margin-top: -14px;
    font-size: 20px;
    color: #9a9a9a;
    margin-left: 54px;
    text-align: left;
}