@import '../../utils//StylesUtils/variables.scss';

.Root {
    width: 100%;
    display: flex;
}

.Root-Tablet {
    width: 100%;
    display: flex;
}

.Root-Mobile {
    width: 100%;
    display: flex;
}

// Drawer Start
.Root .Drawer {
    width: 25%;
}

.Root-Tablet .Drawer-tablet {
    width: 15%;
}

.Root-Mobile .Drawer-Mobile {
    width: 0%;
}

.Drawer .MuiDrawer-paper {
    position: inherit;
    width: 100%;
    height: 100%;
    align-items: center;
    flex-direction: column;
    background-color: #f2f2f2;
}

.Container-Style {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin: 0px 20px 0px 120px;
    position: 'absolute';
}

.Container-Style-Tablet {
    display: flex;
    flex-direction: column;
    width: 85%;
    margin: 0px 20px 0px 60px;
    position: 'absolute';
}

.Container-Style-Mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px 20px 0px 40px;
    position: 'absolute';
}

// Job Description Start
.Job-Title-Div {
    margin-top: 70px;
}

.Job-Title-InputText {
    width: 60%;
}

.Job-Title-InputText .MuiOutlinedInput-root {
    border-radius: 10px;
    font-family: $libreFranklin;
    font-size: $fontSize18pxVw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.81;
    letter-spacing: normal;
    color: #070707;
}

.Job-Description-Div {
    margin-top: 22px;
}

.Job-Description-InputText {
    width: 90%;
}

.Job-Description-InputText .MuiOutlinedInput-root {
    border-radius: 10px;
    font-family: $libreFranklin;
    font-size: $fontSize18pxVw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.81;
    letter-spacing: normal;
    color: #070707;
}

.Job-Additional-Instruction-Div {
    margin-top: 22px;
}

.Job-Additional-Instruction-InputText {
    width: 90%;
}

.Job-Additional-Instruction-InputText .MuiOutlinedInput-root {
    border-radius: 10px;
    font-family: $libreFranklin;
    font-size: $fontSize18pxVw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.81;
    letter-spacing: normal;
    color: #070707;
}
// Job Description End

// Row Start

.Row-Parent {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}

.Row-Parent-Start {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
}

.Row-Parent-Full {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}

.Row-Time-Parent {
    margin-left: 20px;
}

.Row-Parent-Time-Full {
    width: 100%;
    display: -webkit-box;
    flex-direction: row;
    justify-content: start;
}

.Row-Parent-Fixed-Width {
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}

.Row-Parent-Full-Space-Between {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Row-Parent-Medium-Space-Between {
    width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
// Row End

// Fee Payment Start
.Fee-Type-Text {
    font-family: $libreFranklin;
    font-size: $fontSize20pxVw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    margin-top: 72px;
}

.Hourly-Rate-Text {
    font-family: $libreFranklin;
    font-size: $fontSize20pxVw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    margin-top: 35px;
}

.Hourly-Rate-InputText-Parent {
    margin-top: 22px;
}

.Hourly-Rate-InputText {
    width: 63%;
}

.Hourly-Rate-InputText .MuiOutlinedInput-root {
    width: 63%;
    border-radius: 10px;
    font-family: $libreFranklin;
    font-size: $fontSize18pxVw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.81;
    letter-spacing: normal;
    color: #9a9a9a;
}

.Fee-Amount-Text {
    font-family: $libreFranklin;
    font-size: $fontSize20pxVw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    margin-top: 35px;
}

.Fee-Amount-InputText-Parent {
    margin-top: 22px;
}

.Fee-Amount-InputText {
    width: 63%;
}

.Fee-Amount-InputText .MuiOutlinedInput-root {
    width: 63%;
    border-radius: 10px;
    font-family: $libreFranklin;
    font-size: $fontSize18pxVw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.81;
    letter-spacing: normal;
    color: #9a9a9a;
}

.Post-Job-Text {
    font-family: $libreFranklin;
    font-size: $fontSize20pxVw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    margin-top: 35px;
}

.Post-Job-InputText-Parent {
    margin-top: 22px;
}

.Post-Job-InputText {
    width: 63%;
}

.Post-Job-InputText .MuiOutlinedInput-root {
    width: 63%;
    border-radius: 10px;
    font-family: $libreFranklin;
    font-size: $fontSize18pxVw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.81;
    letter-spacing: normal;
    color: #9a9a9a;
}
// Fee Payment End

// Buttom Form Buttons Start
.Bottom-Buttons-Parent {
    display: flex;
    flex-direction: row;
}

.Back-Button-Parent {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.Back-Button-Parent .Back-Button-Class {
    width: 184px;
    height: 48px;
    border-radius: 5px;
    display: flex;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: #9a9a9a;
    text-transform: none;
    cursor: pointer;
}

// Buttom Form Buttons End

//Space Start
.Ultra-Small-Space {
    height: 2vh;
}

.Small-Space {
    height: 3vh;
}

.Normal-Space {
    height: 4vh;
}

.Extra-Space {
    height: 5vh;
}

.Extra-Large-Space {
    height: 6vh;
}

.Horizontal-Ultra-Small-Space-Between {
    width: 5%;
}

.Horizontal-Small-Space-Between {
    width: 20%;
}

.Horizontal-Normal-Space-Between {
    width: 30%;
}

.Horizontal-Extra-Space-Between {
    width: 40%;
}

// Space End

.Temp-Class {
    height: 500px;
}

//Centralize DropDown Start
.CentrlizeDropDown-Parent {
    width: 40%;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    flex-direction: column;
}

.CentrlizeDropDown-Selection {
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: row;
    border-radius: 10px;
    border: 1px solid #b6b7b6;
    letter-spacing: 4px;
    cursor: pointer;
}

.CentrlizeDropDown-Selection .MuiSvgIcon-root {
    margin-right: 10px;
    color: #1876d2;
}

.CentrlizeDropDown-Selection:hover {
    border: 1px solid #1876d2;
}

.CentrlizeDropDown-Parent .CentralizeDropDown-Selection-Menu {
    width: 17%;
    min-height: 0px;
    max-height: 200px;
    position: absolute;
    margin-top: 70px;
    margin-left: 5px;
    elevation: 10;
    z-index: 5;
    border-radius: 16px;
    overflow-y: scroll;
}

.CentrlizeDropDown-Parent .MuiOutlinedInput-root {
    font-family: $libreFranklin;
    font-size: $fontSize18pxVw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.81;
    letter-spacing: normal;
    color: #070707;
}

.CentralizeDropDown-Selection-Text {
    width: 100%;
    font-family: $libreFranklin;
    font-size: $fontSize18pxVw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    padding-left: 10px;
    padding-right: 10px;
}

.CentralizeDropDown-Selection-Menu .CentralizeDropDown-Selection-Menu-Text-Parent {
    width: 100%;
}

.CentralizeDropDown-Selection-Menu .CentralizeDropDown-Selection-Menu-Text-Parent:hover {
    width: 100%;
    background-color: #ffffff;
}

.CentralizeDropDown-Selection-Menu .CentralizeDropDown-Selection-Menu-Text {
    width: 100%;
    font-family: $libreFranklin;
    font-size: $fontSize18pxVw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    padding: 10px 10px 10px 10px;
    margin-left: 5px;
    margin-right: 5px;
}

.CentralizeDropDown-Selection-Menu .CentralizeDropDown-Selection-Menu-Text:hover {
    color: white;
    background-color: #1876d2;
    border-radius: 10px;
    border: 0px solid #707070;
}
//Centralize DropDown End

//Centralise Search chips Start
.Centrlize-Search-Parent {
    width: 100%;
    justify-content: start;
    align-items: flex-start;
    flex-direction: column;
}

.Centrlize-Search-Parent .CentralizeSearch-Selection-Menu {
    width: 16%;
    min-height: 0px;
    max-height: 200px;
    position: absolute;
    margin-left: 5px;
    elevation: 10;
    z-index: 5;
    border-radius: 16px;
    overflow-y: scroll;
}

.Centralize-Search-InputTex-Parent.MuiFormControl-root {
    width: 80%;
}

.Centralize-Search-InputText {
    width: 40%;
}

.Centralize-Search-InputText .MuiOutlinedInput-root {
    width: 100%;
    border-radius: 10px;
    font-family: $libreFranklin;
    font-size: $fontSize18pxVw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.81;
    letter-spacing: normal;
    color: #9a9a9a;
}

.CentralizeSearch-Selection-Menu .CentralizeSearch-Selection-Menu-Text-Parent {
    width: 100%;
}

.CentralizeSearch-Selection-Menu .CentralizeSearch-Selection-Menu-Text-Parent:hover {
    width: 100%;
    background-color: #ffffff;
}

.CentralizeSearch-Selection-Menu .CentralizeSearch-Selection-Menu-Text {
    width: 100%;
    font-family: $libreFranklin;
    font-size: $fontSize18pxVw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    padding: 10px 10px 10px 10px;
    margin-left: 5px;
    margin-right: 5px;
}

.CentralizeSearch-Selection-Menu .CentralizeSearch-Selection-Menu-Text:hover {
    color: white;
    background-color: #1876d2;
    border-radius: 10px;
    border: 0px solid #707070;
}

.Centralize-Search-Chips-List-Parent {
    width: 80%;
}

.Centralize-Search-Chips-Parent {
    flex-direction: row;
    display: inline-block;
}

.Centralize-Search-Chips-Parent .MuiChip-label {
    color: #ffffff;
}

.Centralize-Search-Chips-Parent .MuiChip-root {
    color: #ffffff;
    margin: 4px;
    height: 45px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #1876d2;
}

.Centralize-Search-Chips-Parent .MuiSvgIcon-root {
    color: #ffffff;
}

.Centralize-Search-Chips-Parent .MuiChip-clickable.MuiChip-outlined:hover {
    background-color: #1876d2;
}

.Centralize-Search-Chips-Parent .MuiChip-clickable.MuiChip-outlined:focus,
.MuiChip-deletable.MuiChip-outlined:focus {
    background-color: #1876d2;
}

.Centralize-Search-Chips-Parent-Grey {
    flex-direction: row;
    display: inline-block;
}

.Centralize-Search-Chips-Parent-Grey .MuiChip-label {
    color: #707070;
}

.Centralize-Search-Chips-Parent-Grey .MuiChip-root {
    color: #707070;
    margin: 4px;
    height: 45px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #d9d9d9;
}

.Centralize-Search-Chips-Parent-Grey .MuiSvgIcon-root {
    color: #707070;
}

.Centralize-Search-Chips-Parent-Grey .MuiChip-clickable.MuiChip-outlined:hover {
    background-color: #d9d9d9;
}

.Centralize-Search-Chips-Parent-Grey .MuiChip-clickable.MuiChip-outlined:focus,
.MuiChip-deletable.MuiChip-outlined:focus {
    background-color: #d9d9d9;
}

.Centralize-Search-Chips-List-Parent-Small {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
}

.Centralize-Search-Chips-Parent-Small {
    flex-direction: row;
    display: inline-block;
}

.Centralize-Search-Chips-Parent-Small .MuiChip-label {
    color: #ffffff;
}

.Centralize-Search-Chips-Parent-Small .MuiChip-root {
    color: #ffffff;
    margin: 4px;
    padding-left: 2px;
    padding-right: 2px;
    background-color: #1876d2;
    width: 100%;
}

.Centralize-Search-Chips-Parent-Small .MuiSvgIcon-root {
    color: #ffffff;
}

.Centralize-Search-Chips-Parent-Small .MuiChip-clickable.MuiChip-outlined:hover {
    background-color: #1876d2;
}

.Centralize-Search-Chips-Parent-Small .MuiChip-clickable.MuiChip-outlined:focus,
.MuiChip-deletable.MuiChip-outlined:focus {
    background-color: #1876d2;
}
box
//Centralize Search Chips End




// Centralise Input Text start

.Centralize-InputText {
    width: 100%;
}

.Centralize-InputText .MuiOutlinedInput-root {
    width: 100%;
    border-radius: 10px;
    font-family: $libreFranklin;
    font-size: $fontSize18pxVw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #9a9a9a;
}

.Centralize-InputText .MuiFormLabel-root {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    right: 22px;
    bottom: 0px;
}

.Centralize-InputText-Small {
    width: 40%;
}

.Centralize-InputText-Small .MuiOutlinedInput-root {
    width: 100%;
    border-radius: 10px;
    font-family: $libreFranklin;
    font-size: $fontSize18pxVw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #9a9a9a;
}

.Centralize-InputText-Small .MuiFormLabel-root {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    right: 22px;
    bottom: 0px;
}

.Centralize-InputText-Large {
    width: 60%;
}

.Centralize-InputText-Large .MuiOutlinedInput-root {
    width: 100%;
    border-radius: 10px;
    font-family: $libreFranklin;
    font-size: $fontSize18pxVw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #9a9a9a;
}

.Centralize-InputText-Large .MuiFormLabel-root {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    right: 22px;
    bottom: 0px;
}

.Centralize-InputText-Full {
    width: 90%;
}

.Centralize-InputText-Full .MuiOutlinedInput-root {
    width: 100%;
    border-radius: 10px;
    font-family: $libreFranklin;
    font-size: $fontSize18pxVw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #9a9a9a;
}

.Centralize-InputText-Full .MuiFormLabel-root {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    right: 22px;
    bottom: 0px;
}

.Centralize-InputText-Fix-Width {
    width: 242px;
}

.Centralize-InputText-Fix-Width .MuiOutlinedInput-root {
    width: 100%;
    border-radius: 10px;
    font-family: $libreFranklin;
    font-size: $fontSize18pxVw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #9a9a9a;
}

.Centralize-InputText-Fix-Width .MuiFormLabel-root {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    right: 22px;
    bottom: 0px;
}

.Centralize-InputText-Small-Mobile {
    width: 40%;
}

.Centralize-InputText-Small-Mobile .MuiOutlinedInput-root {
    width: 100%;
    border-radius: 10px;
    font-family: $libreFranklin;
    font-size: $fontSize18pxVw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #9a9a9a;
}

.Centralize-InputText-Small-Mobile .MuiFormLabel-root {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    right: 22px;
    bottom: 0px;
}

.Centralize-InputText-Large-Mobile {
    width: 80%;
}

.Centralize-InputText-Large-Mobile .MuiOutlinedInput-root {
    width: 100%;
    border-radius: 10px;
    font-family: $libreFranklin;
    font-size: $fontSize22pxVw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #9a9a9a;
}

.Centralize-InputText-Large-Mobile .MuiFormLabel-root {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    right: 10px;
    bottom: 0px;
}
// Centralise Input Text end

// Centralise Text Start
.Centralise-Text-Heading {
    font-family: $libreFranklin;
    font-size: 2vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #070707;
}

.Centralise-Text-Heading-Mobile {
    font-family: $libreFranklin;
    font-size: 3.9vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #070707;
}

.Centralise-Text {
    font-family: $libreFranklin;
    font-size: 1.04166vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #070707;
}

.Centralise-Text-Mobile {
    font-family: $libreFranklin;
    font-size: 2vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #070707;
}

.Centralise-Text-With-Border {
    font-family: $libreFranklin;
    font-size: 1.04166vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #070707;
    border: 1px solid #b6b7b6;
    padding: 10px 15px 10px 15px;
    border-radius: 10px;
}

.Centralise-Text-With-Border-Mobile {
    font-family: $libreFranklin;
    font-size: 2vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #070707;
    border: 1px solid #b6b7b6;
    padding: 10px 15px 10px 15px;
    border-radius: 10px;
}

.Centralise-Text-With-Border-Fixed-Width {
    font-family: $libreFranklin;
    font-size: 1.04166vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #9a9a9a;
    cursor: pointer;
    border: 1px solid #b6b7b6;
    padding: 20px 15px 20px 15px;
    border-radius: 10px;
    width: 100%;
}

.Centralise-Text-With-Border-Fixed-Width-Mobile {
    font-family: $libreFranklin;
    font-size: 2vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #9a9a9a;
    cursor: pointer;
    border: 1px solid #b6b7b6;
    padding: 20px 15px 20px 15px;
    border-radius: 10px;
    width: 100%;
}

.Centralise-Light-Text {
    font-family: $libreFranklin;
    font-size: 1.04166vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #9a9a9a;
}

.Centralise-Light-Text-Mobile {
    font-family: $libreFranklin;
    font-size: 2vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #9a9a9a;
}

.Centralise-Light-Text-Cursor {
    font-family: $libreFranklin;
    font-size: 1.04166vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #9a9a9a;
    cursor: pointer;
}

.Centralise-Light-Text-Cursor-Mobile {
    font-family: $libreFranklin;
    font-size: 2vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #9a9a9a;
    cursor: pointer;
}
// Centralise Text End

//Centralize Chips Selected start

.Centralise-Chips-Selected-List-Parent {
    width: 80%;
}

.Centralise-Chips-Parent {
    flex-direction: row;
    display: inline-block;
}

.Centralise-Chips-Parent .MuiChip-root {
    margin: 4px;
    height: 45px;
    padding-left: 15px;
    padding-right: 15px;
}

.Centralise-Chips-Parent .MuiSvgIcon-root {
}

.Centralise-Chips-Parent-Selected {
    flex-direction: row;
    display: inline-block;
}

.Centralise-Chips-Parent-Selected .MuiChip-label {
    color: #ffffff;
}

.Centralise-Chips-Parent-Selected .MuiChip-root {
    color: #ffffff;
    margin: 4px;
    height: 45px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #1876d2;
}

.Centralise-Chips-Parent-Selected .MuiSvgIcon-root {
    color: #ffffff;
}

.Centralise-Chips-Parent-Selected .MuiChip-clickable.MuiChip-outlined:hover {
    background-color: #1876d2;
}

.Centralise-Chips-Parent-Selected .MuiChip-clickable.MuiChip-outlined:focus,
.MuiChip-deletable.MuiChip-outlined:focus {
    background-color: #1876d2;
}

//Centralize Chips Selected end

//Centralize Check Box start
.Centralise-CheckBox-Parent {
    width: 100%;
    border-radius: 10px;
    font-family: $libreFranklin;
    font-size: $fontSize18pxVw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.81;
    letter-spacing: normal;
    color: #707070;
    padding-right: 10px;
}
//Centralize Check Box end

//Centralize Toggle Button start

.Centralise-Toggle-Button-Parent-Left .MuiToggleButton-root.Mui-selected {
    border-radius: 8px 0 0 8px;
    background-color: #1e7bc9;
    color: #ffffff;
}

.Centralise-Toggle-Button-Parent-Left .MuiToggleButton-root.Mui-selected:hover {
    border-radius: 8px 0 0 8px;
    background-color: #1e7bc9;
    color: #ffffff;
}

.Centralise-Toggle-Button-Parent-Left-disabled .MuiToggleButton-root.Mui-selected {
    border-radius: 8px 0 0 8px;
    background-color: grey;
    color: #ffffff;
}

.Centralise-Toggle-Button-Parent-Left-disabled .MuiToggleButton-root.Mui-selected:hover {
    border-radius: 8px 0 0 8px;
    background-color: grey;
    color: #ffffff;
}


.Centralise-Toggle-Button-Parent-Right-disabled .MuiToggleButton-root.Mui-selected {
  border-radius: 0 8px 8px 0;
  background-color: grey;
  color: #ffffff;
}

.Centralise-Toggle-Button-Parent-Right-disabled .MuiToggleButton-root.Mui-selected:hover {
  border-radius: 0 8px 8px 0;
  background-color: grey;
  color: #ffffff;
}


.Centralise-Toggle-Button-Parent-Right .MuiToggleButton-root.Mui-selected {
    border-radius: 0 8px 8px 0;
    background-color: #1e7bc9;
    color: #ffffff;
}

.Centralise-Toggle-Button-Parent-Right .MuiToggleButton-root.Mui-selected:hover {
    border-radius: 0 8px 8px 0;
    background-color: #1e7bc9;
    color: #ffffff;
}

.Centralise-Toggle-Button-Parent-Left .MuiToggleButton-root {
    border-radius: 0 8px 8px 0;
    background-color: #ffffff;
    color: #c7c7c7;
}

.Centralise-Toggle-Button-Parent-Left .MuiToggleButton-root:hover {
    border-radius: 0 8px 8px 0;
    background-color: #ffffff;
    color: #c7c7c7;
}

.Centralise-Toggle-Button-Parent-Right .MuiToggleButton-root {
    border-radius: 8px 0 0 8px;
    background-color: #ffffff;
    color: #c7c7c7;
}

.Centralise-Toggle-Button-Parent-Right .MuiToggleButton-root:hover {
    border-radius: 8px 0 0 8px;
    background-color: #ffffff;
    color: #c7c7c7;
}

//Centralize Toggle Button end

// Centralize Date Picker Start
.React-DatePicker-Single .rmdp-range {
    font-family: $libreFranklin, Helvetica, Arial, sans-serif;
    font-size: 0.8rem;
    border: 10px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-shadow: 0 0 0px #8798ad;
    background-color: rgba(72, 141, 255, 0.3);
    color: rgba(69, 89, 151, 1);
}

.React-DatePicker .rmdp-container {
    max-width: 600px;
    min-width: 600px;
}

.React-DatePicker .rmdp-range {
    font-family: $libreFranklin, Helvetica, Arial, sans-serif;
    font-size: 0.8rem;
    border: 10px;
    display: inline-block;
    position: relative;
    box-shadow: 0 0 0px #8798ad;
    background-color: rgba(72, 141, 255, 0.3);
    color: rgba(69, 89, 151, 1);
}

.React-DatePicker .rmdp-week-day {
    color: #b2b2b2;
}

.React-DatePicker-Single .rmdp-week-day {
    color: #b2b2b2;
}

.React-DatePicker .rmdp-wrapper {
    border-radius: 20px;
}

.React-DatePicker-Single .rmdp-wrapper {
    border-radius: 20px;
}

.React-DatePicker .rmdp-arrow {
    border: solid #d9d9d9;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    height: 3px;
    width: 3px;
    margin-top: 5px;
}

.React-DatePicker-Single .rmdp-arrow {
    border: solid #d9d9d9;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    height: 3px;
    width: 3px;
    margin-top: 5px;
}

// Centralize Date Picker End

// Centralize Radio Button Start

.Radio-Button-Parent .MuiRadio-colorSecondary.Mui-checked {
    color: #707070;
}
// Centralize  Radio Button End

//Centralize Buttons
.Centralize-Button-Parent {
    margin-right: 10%;
}

.Centralize-Button-Parent .Centralize-Button-Class {
    height: 48px;
    padding: 6.8px 35.5px;
    border-radius: 5px;
    background-image: linear-gradient(to bottom, #2699fb, #134d7e);
    font-size: $fontSize18pxVw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: #ffffff;
    text-transform: none;
}
// Centralize Button End

//Centralize Data Table Start

.Centralize-Data-Table-Parent {
    width: 60%;
}

.Centralize-Data-Table {
    width: 100%;
    padding: 30px 15px 10px 15px;
    border: 1px solid #c7c7c7;
}

.Centralize-Data-Table .MuiTable-root {
    width: 100%;
}

.Centralize-Data-Table .MuiPaper-elevation1 {
    width: 100%;
    border-radius: 10px;
}

.Centralize-Data-Table-Parent .MuiPaper-rounded {
    border-radius: 10px;
}

.Centralize-Data-Table .MuiTableContainer-root {
    height: 320px;
}

.Centralize-Data-Table-Div {
    height: 320px;
}

.Centralize-Data-Table .MuiTableCell-head {
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    color: #1c1c1c;
}

.Centralize-Data-Table .MuiTableCell-head .MuiSvgIcon-root {
    color: #9a9a9a;
    padding: 10px 0px 0px 0px;
}

.Centralize-Data-Table .MuiCheckbox-root {
    color: #9a9a9a;
}

.Centralize-Data-Table .MuiTableCell-body {
    color: #1c1c1c;
}

.Centralize-Data-Table .MuiTableCell-stickyHeader {
    background-color: #ffffff;
}

.Centralize-Data-Table .MuiTableRow-root.Mui-selected {
    background-color: rgba(72, 141, 255, 0.3);
}

.Centralize-Data-Table .MuiTableRow-root.Mui-selected:hover {
    background-color: rgba(72, 141, 255, 0.3);
}

.Centralize-Data-Table .MuiCheckbox-colorSecondary.Mui-checked {
    color: #2b7bff;
}

//Centralize Data Table End

// Alert Button Border Start
.swal2-container .swal2-popup {
    border-radius: 30px;
}

// Alert Button Border End

//File Attachment Start
.fileDropLabel {
    font-family: $HelveticaNeue;
    font-size: $fontSize20pxVw;
    text-align: center;
    color: #707070;
}

.Dropbox-Wrapper {
    width: 90%;
    background-color: #f7f7f7;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    border: 0.5px dashed #b6b7b6;
    letter-spacing: 2px;
    padding-bottom: 10px;
    cursor: pointer;
}

.fileAttachment-Parent {
    width: 100%;
    height: 100%;
    margin: 30px 10px 30px 10px;
}

.fileDropChipsWrapper {
    margin-top: -1.4vw;
    display: flex;
}
// File Attachment End

.Loader {
    background: grey;
    background-size: 100%;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.Loader .MuiCircularProgress-colorPrimary {
    color: #1876d2;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-left: 30px;
}

.Loader-Text {
    font-family: $libreFranklin;
    font-size: $fontSize36pxVw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin-top: 10px;
}

.Fill-Remaining-Space {
    flex: 1;
}
