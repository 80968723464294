.empolink-heading {
    color: white;
    position: absolute;
    margin-top: 110px;
    margin-left: 110px;
    font-size: 35px;
    letter-spacing: 2.84px;
    font-family: 'libre franklin';
}

.signUpHeading{
    font-family: 'Libre Franklin';
    font-size: 38px;
    font-weight: bold;
    margin: 0px;
    padding-top: 111px;
    color: #414141
}

.signUpSubHeading{
    font-family: 'Libre Franklin';
    font-size: 26px;
    color: #707070;
    padding-bottom: 148.5px;
}

.registerSpecialityHeadingStyles{
    width: 448px;
    height: 24px;
    font-family: 'Libre Franklin';
    font-size: 20px;
    text-align: left;
    color: #707070;
    margin-top: 47px;
    margin-bottom: 15px;
}

.backIconButtonStyles{
    position: absolute;
    margin-left: 35%;
    margin-top: 10%;
    border-radius: 50%;
    padding: 1%;
    background-color: linen;
}

.backIconButtonStyles :hover{
    color: #000000;
    // padding: 1vw;
    // background-color: #c4c4c4;
}

.backIconButtonStyles :active{
    color: #1876d2;
    // background-color: #1876d2;
}