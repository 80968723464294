@import '../../utils/StylesUtils/variables.scss';

.sub-row-name {
    margin: 0;
    font-size: 20px;
}

.sub-row-title {
    font-size: 18px;
    color: #9A9A9A;
    margin-top: 10px;
    font-family: $libreFranklin;
}

.sub-row-title-second {
    font-size: 18px;
    color: #9A9A9A;
    margin-top: 8px;
}