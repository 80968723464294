body {
    margin: 0;
    padding: 0;
    font-family: 'Libre Franklin', sans-serif;
}
.fst-col ul li a:hover,
    .quick-link ul li a:hover {
        color: #009FF5; 
    }
input:focus {
    text-shadow: none;
    outline: none;
}

.container {
    max-width: 1310px;
    padding: 0 15px;
}

.page-header {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 15px 0 15px 0;
    z-index: 9;
    background-color: #232f3e;/*#232f3e/*#324D99; /* header color at non-fixed state */
}

.page-header .logo span {
    width: 0;
    display: flex;
}

.page-header.fixed{
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    padding: 15px 0 15px 0;
    box-shadow: 0 6px 26px rgb(0 0 0 / 4%);
}
.page-header.fixed .logo span{
    width: 100%;
}
.page-header.fixed .logo img{
    display: none;
}
.page-header.fixed .logo span img{
    display: inline-block;
}
.header-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.logo {
    width: 120px;
}

.logo img {
    width: 100%;
}

.header-button ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.header-button ul li {
    padding: 0 0 0 10px;
    font-weight: 600;
    font-size: 12px;
}
/* Header Button Styles for NON-FIXED STATE (not scrolled) */
.header-button ul li a.signup {
    border: 1px solid #009FF5; /* Header button signup border at non-fixed state */
    text-decoration: none;
    color: #fff;
    display: block;
    border-radius: 24px;
    line-height: 18px;
    padding: 8px 15px;
    margin-bottom: 25px;
    background-color: transparent; /* Default background for signup button */
}

.header-button ul li a.login {
    border: 2px solid #009FF5; /* Header button login border at non-fixed state */
    text-decoration: none;
    color: #fff;
    display: block;
    border-radius: 24px;
    line-height: 18px;
    padding: 8px 15px;
    margin-bottom: 25px;
    background-color: #009FF5; /* Header button login background at non-fixed state */
}

.header-button ul li a.signup:hover {
    background-color: #009FF5; /* Header button signup hover at non-fixed state */
    border-color: #009FF5;
    color: #fff !important;
}

.header-button ul li a.login:hover {
    background-color: #efeeeb; /* Header button login hover at non-fixed state */
    border-color: #009FF5;
    color: #009FF5;
}

/* Header Button Styles for FIXED STATE (scrolled) */
.fixed .header-button ul li a.signup {
    color: #10578d; /* Header button signup text at fixed state */
    border: 1px solid #10578d; /* Header button signup border at fixed state */
    background-color: transparent; /* Default background for signup button */
}

.fixed .header-button ul li a.login {
    color: #fff; /* Header button login text at fixed state */
    border: none;
    background: rgb(2,148,230); /* Header button login background at fixed state */
    background: linear-gradient(180deg, rgba(2,148,230,1) 0%, rgba(16,87,141,1) 100%);
}

.fixed .header-button ul li a.signup:hover {
    background: rgb(2,148,230); /* Header button signup hover at fixed state */
    background: linear-gradient(180deg, rgba(16,87,141,1) 0%, rgba(2,148,230,1) 100%);
}

.fixed .header-button ul li a.login:hover {
    background: rgb(2,148,230); /* Header button login hover at fixed state */
    background: linear-gradient(180deg, rgba(16,87,141,1) 0%, rgba(2,148,230,1) 100%);
}

@media (min-width:768px) {

    .logo {
        width: 185px; 
    }

    .header-button ul li {
        padding: 0 0 0 20px;
        font-size: 14px;
    }

    .header-button ul li a {
        padding: 12px 35px;
    }
}

@media (min-width:1200px) {
    .logo {
        width: 152px; 
    }

    .header-button ul li a {
        padding: 14px 35px;
    }

    .page-header {
        padding: 35px 0 0 0;
    }
}

.masthead {
    position: relative;
    margin: 0;
    padding: 80px 0 30px 0;
    background: rgb(255, 255, 255);
    color: #232f3e;/*#292828;*/
    min-height: 100vh;
}

.masthead__flex {
    align-items: center;
}

.masthead, .image-text {
    position: relative;
    z-index: 1; /* Ensure these sections are above the logo */
    background: transparent; /* Ensure background is transparent */
}

.masthead__img img {
    display: block;
    max-width: 90%;
    margin: 0 auto;
    padding-bottom: 30px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    border-radius: 10px; /* Optional: adds rounded corners */
}
.masthead h1 {
    font-weight: 500;
    font-size: 25px;
    margin: 0;
    padding: 0;
    line-height: 1.2em;
}

.masthead h1 span {
    color: #009FF5;
    font-weight: 900;
}
.masthead h2 {
    font-weight: 500;
    font-size: 25px;
    margin: 0;
    padding: 0;
    line-height: 1.2em;
}

.masthead h2 span {
    color: #009FF5;
    font-weight: 900;
}

.masthead p {
    font-size: 14px;
    margin: 0;
    padding: 20px 0;
    line-height: 1.8em;
}

.yellow-text {
    color: #009FF5; /* Yellow color for span inside p - Description text within span */
}

.small-font {
    /*font-size: smaller; */
    font-size: 65%;
}

.btn-primary {
    border: none;/*1px solid #D6AF12;*/
    text-decoration: none;
    color: #fff;
    display: inline-block;
    border-radius: 24px;
    line-height: 18px;
    padding: 15px 25px;
    /*background-color: #D6AF12;*/
    background: rgb(2,148,230);
    background: linear-gradient(180deg, rgba(2,148,230,1) 0%, rgba(16,87,141,1) 100%);
    font-size: 14px;
    font-weight: 700;
}


@media (min-width: 768px) {
    .masthead {
        padding: 140px 0 50px 0;
    }

    .masthead__flex {
        flex-direction: row-reverse;
    }
    .masthead h1 {
        font-size: 35px;
    }
    .masthead h2 {
        font-size: 35px;
    }

    .masthead p {
        font-size: 16px;
    }
    
    .btn-primary {
        font-size: 16px;
    }
    
    .masthead__img img {
        max-width: 80%;
    }

    .masthead__img {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        max-width: 470px;
        margin: 0 auto;
    }

    .masthead__img img {
        padding-bottom: 0;
        margin: 0;
    }
}

@media (max-width: 767px) {
    .masthead {
        padding: 80px 20px 20px 20px; /* Adjust as needed */
    }

    .masthead__flex {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .masthead h1 {
        font-size: 28px;
        margin-top: 20px; /* Add margin to create space above the text */
    }
    .masthead h2 {
        font-size: 28px;
        margin-top: 20px; /* Add margin to create space above the text */
    }

    .masthead p {
        font-size: 14px;
        margin: 10px 0; /* Add margin to create space */
    }

    .btn-primary {
        font-size: 14px;
        margin-top: 20px; /* Add margin to create space above the button */
    }

    .masthead__img {
        margin-top: 50px;
        margin-bottom: 20px; /* Add margin to create space below the image */
    }

    .masthead__img img {
        max-width: 100%;
    }
}

@media (min-width: 1200px) {
    .masthead {
        padding: 152px 0 80px 0;
    }
    .masthead h1 {
        font-size: 51px;
    }
    .masthead h2 {
        font-size: 51px;
    }

    .masthead p {
        font-size: 17px;
        padding: 24px 0 42px 0;
    }
    
    .btn-primary {
        font-size: 17px;
        padding: 19px 35px;
        border-radius: 50px;
    }
    
    .masthead__img img {
        max-width: 90%;
    }

    .btn-primary:hover {
        /*background-color: #009FF5;*/
        background: rgb(2,148,230);
        background: linear-gradient(180deg, rgba(16,87,141,1) 0%, rgba(2,148,230,1) 100%);
    }    
}

.image-text__img img {
    max-width: 350px;
    margin: 0 auto;
    display: block;
    width: 90%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    border-radius: 10px; /* Optional: adds rounded corners */
}

.section-padding {
    padding: 60px 0;
}

.blue-bg {
    background-color: #E9F5FF;
}

.lightgrey-bg {
    background-color: rgba(239, 238, 235, 0.65); /*#efeeeb; */
}

.image-text__text {
    padding-top: 30px;
    color: #161616;
}

.image-text__text p {
    font-size: 14px;
    margin: 0;
    padding: 20px 0 0 0;
    line-height: 1.5em;
}

.image-text__text h2 {
    font-size: 25px;
    margin: 0;
    padding: 0 0 0 0;
    color: #040918;
    text-transform: uppercase;
    font-weight: 600;
}

.image-text__text h2 span {
    color: #009FF5; /* other header text in span -not hero section */
    font-weight: 800;
}

@media (min-width: 768px) {

    .section-padding {
        padding: 80px 0;
    }

    .flex-row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    
    .image-text .flex-row {
        align-items: center
    }

    .image-text .flex-direction {
        flex-direction: row-reverse !important;
    }
    
    .image-text__img img {
        margin: 0;
        max-width: 90%;
    }

    .image-text__text {
        padding-top: 0;
    }

    .image-text--left .image-text__text {
        padding-left: 20px;
    }

    .image-text--right .image-text__text {
        padding-right: 20px;
    }

    .image-text__text p {
        font-size: 17px;
    }
    
    .image-text__text h2 {
        font-size: 35px;
    }
}

@media (min-width: 1200px) {

    .section-padding {
        padding: 120px 0;
    }

    .image-text--left .image-text__text {
        padding-left: 85px;
    }
    
    .image-text--right .image-text__text {
        padding-right: 85px;
    }

    .image-text__text p {
        font-size: 19px;
        padding-top: 30px;
    }
    
    .image-text__text h2 {
        font-size: 48px;
    }

}
@media (min-width: 1400px) {
    .image-text{
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .image-text__img img {
        max-width: 100%;
    }
}
.page-footer {
    background-color: #233975;
    padding: 40px 0 0 0;
    color: #fff;
    position: relative;
    z-index: 2;
}
.footer-link-wrap {
    display: flex;
    justify-content: space-between;
    width: 50%;
}
.top-footer {
    padding-bottom: 40px;
    background-color: inherit;
}

.footer-logo {
    width: 120px;
    margin-bottom: 10px;
}

.footer-logo img {
    width: 100%;
    display: block;
}

.fst-col p {
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 1.4em;
    padding: 20px 0;
}


.fst-col ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.fst-col ul li {
    padding: 0 10px 0 0;
    margin: 0;
    display: inline-block;
    font-size: 14px;
}

.fst-col ul li i {
    font-size: 18px;
    vertical-align: middle;
    padding-right: 10px;
    padding-bottom: 3px;
}

.fst-col ul li a {
    color: #fff;
    text-decoration: none;
}

/* .quick-link {
    padding-top: 20px;
} */

.quick-link h3 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    padding: 5px 0 25px 0;
}

.quick-link ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.quick-link ul li {
    padding: 15px 0 0 0;
    margin: 0;
    display: block;
    font-size: 14px;
}

.quick-link ul li a {
    color: #fff;
    text-decoration: none;
    opacity: 0.89;
}

.copyright {
    border-top: 1px solid rgb(255 255 255 / 69%);
    padding: 15px 0;
    text-align: center;
    font-size: 14px;
    opacity: 0.59;
}

@media (min-width: 768px) {
    /* .page-footer {
        padding: 60px 0 0 0;
    } */
    
    .top-footer {
        padding-bottom: 60px;
    }

    .footer-logo {
        width: 185px;
        margin-bottom: 20px;
    }

    /* .quick-link { 
        padding-top: 40px;
    } */
    .footer-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

@media (min-width: 1200px) {
    .page-footer {
        padding: 45px 0 0 0;
    }
    
    .top-footer {
        padding-bottom: 45px;
    }

    .footer-logo {
        width: 152px;
        margin-bottom: 30px;
    }

    .quick-link { 
        padding-top: 0px;
    }

    .fst-col {
       width: 50%;
    }

    .fst-col p {
        padding: 33px 0;
        font-size: 17px;
    }

    .quick-link ul li {
        font-size: 14px;
    }

    .fst-col ul li {
        padding-right: 25px;
        font-size: 17px;
    }

    .fst-col ul li:last-child {
        padding-right: 0;
    }

    .fst-col ul li i {
        padding-right: 10px;
    }

    .copyright {
        padding: 20px 0;
    }
    .quick-link h3 {
        padding: 10px 0 20px 0;
    }
}

.login-wrapper {
    min-height: 100vh;
}
.login-wrapper .container{
    max-width: unset;
}

.login-img {
    width: 100%;
    display: block;
    text-align: center;
    padding: 100px 0 30px;
    
}
.login-wrapper .col-md-6:first-child{
    background: #E2F5FF;
}


.login-img img {
    width: 250px;
    
    margin: 0 auto;
}

.login-right {
    padding-top: 30px;
}

.login-right h2 {
    font-size: 22px;
    color: #343A40;
    margin: 0;
    font-weight: 700;
    padding: 0 0 20px 0;
}

.form-col {
    padding-top: 20px;
}

.form-col label {
    /* display: block; */
    color: #343A40;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Libre Franklin', sans-serif;
}

.form-col input[type="text"],
.form-col input[type="email"],
.form-col input[type="password"],
.form-col input[type="tel"] {
    background-color: #fff;
    width: 100%;
    height: 56px;
    /* border: 2px solid #C7C7C7; */
    border-radius: 6px;
    color: #6C757D;
    padding: 0 15px;
    font-family: 'Libre Franklin', sans-serif;
    font-size: 14px;
}
.form-col input[type="text"]::placeholder,
.form-col input[type="email"]::placeholder,
.form-col input[type="password"]::placeholder,
.form-col input[type="tel"]::placeholder {
    color: #9A9A9A;
}

.form-col input[type="submit"] {
    background: linear-gradient(180deg, #2594F4 0%, #15548A 100%);
    width: 100%;
    height: 56px;
    border: none;
    border-radius: 6px;
    color: #fff;
    text-align: center;
    font-family: 'Libre Franklin', sans-serif;
    font-weight: 700;
    font-size: 14px;
}

.form-style {
    position: relative;
}

.form-style::after {
    position: absolute;
    font-family: 'fontawesome';
    font-size: 22px;
    color: #ADB5BD;
    line-height: 56px;
    left: 24px;
    top: 0;
}

/*.form-email::after {
    content: '\f003 ';
}

.form-password::after {
    content: '\f023';
}*/

.form-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.form-left label {
    font-weight: 400;
    color: #939395;
    padding-bottom: 0;
}

.form-left input {
    margin-right: 5px;
}

.form-right a {
    font-size: 14px;
    color: #1A66A7;
    text-decoration: none;
    font-weight: 600;
}

.form-seperator {
    margin: 50px 0;
    position: relative;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #97999C;
}

.form-seperator::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #EBEBEB;
    margin-top: -1px;
}


.form-seperator span {
    position: relative;
    background-color: #FAFEFF;
    display: inline-block;
    padding: 0 15px;
}

.login-method {
    background-color: #fff;
    width: 100%;
    height: 60px;
    border: 2px solid #C7C7C7;
    border-radius: 6px;
    color: #6C757D;
    padding-left: 70px;
    padding-right: 15px;
    font-family: 'Libre Franklin', sans-serif;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    position: relative;

}

.login-method img {
    position: absolute;
    left: 24px;
    top: 50%;
    margin-top: -15px;
}

.login-method a {
    display: block;
    padding-right: 80px;
    text-decoration: none;
    color: #888888;
}

.form-link {
    text-align: center;
    color: #939395;
    font-size: 14px;
    padding-top: 20px;
}

.form-link a {
    text-decoration: none;
    color: #64B6FC;
    font-weight: 500;
}
.login-wrapper .row-center{
        min-height: 100vh;
    }
    .login-logo{
        margin: 20px auto 0;
        display: table;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
    .login-logo img{
        width: 152px !important;
        margin: 0 auto;
    }
    .toggle-password{
        position: absolute;
        top: 50%;
        right: 25px;
        transform: translate(0, -50%);
        color: #C8C8C8;
        font-size: 20px;
        cursor: pointer;
    }

    .user-body .login-right {
    padding: 0;
    max-width: unset;
}
.log_in_left_btn{
    width: 100%;
    max-width: 530px;
    border-radius: 6px;
    line-height: 45px;
    text-align: center;
    margin: 10% auto 0;
    display: table;
    color: #fff;
    font-weight: 700;
    text-decoration: none;
    transition: all ease 0.3s;
    font-size: 16px;
    background: rgb(2,148,230);
    background: linear-gradient(180deg, rgba(2,148,230,1) 0%, rgba(16,87,141,1) 100%);
}
.log_in_left_btn:hover{
    color: #fff;
        background: rgb(2,148,230);
    background: linear-gradient(180deg, rgba(16,87,141,1) 0%, rgba(2,148,230,1) 100%);
}
@media (min-width: 768px) {
    .sign-up-body .login-logo {
        padding-left: 9px;
    }

    .login-logo {
        position: relative;
        margin: 40px 0 0;
        max-width: 711px;
        margin: 0 auto;
        width: 100%;
        padding-left: 10px;
    }

    .user-body .login-logo {
        max-width: 490px;
        padding-left: 0;
    }
    .user-body .login-right .login-img {
        justify-content: flex-end;
    }
    .login-logo img{
        float: left;
    }
    .login-wrapper .row-center {
        align-items: center;
    }

    .login-right {
        padding-top: 10%;
        padding-left: 30px;
        padding-bottom: 15px;
    }
    .login-wrapper .row-center{
        align-items: inherit;
    }
    .login-wrapper .col-md-6:first-child{
        display: flex;
        align-items: center;
        position: relative;
        flex-direction: column;
    }
    .login-img {
        padding: 0 90px 90px 90px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }
    .user-body .login-right{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
    }
    .login-img img{
        width: auto;
        margin: 0 auto;
        max-width: 100%;
        padding-top: 20px;
    }
}


@media (min-width: 1200px) {
    .log_in_left_btn{
        line-height: 60px;
        font-size: 24px;
    }
    .login-logo img{
        width: 152px;
        float: left;
        padding-top: 42px;
    }
    .form-col input[type="submit"]:hover {
        background: linear-gradient(180deg, #15548A 0%, #2594F4 100%);
    }

    .login-img {
        width: 100%;
    }

    .login-right {
        max-width: 488px;
        margin: 0 auto;
    }

    .login-right h2 {
        font-size: 40px;
    }

    .form-col {
        padding-top: 28px;
    }

    .form-col label {
        font-size: 17px;
    }

    .form-right a,
    .form-left label {
        font-size: 16px;
    }

    .form-col input[type="text"],
    .form-col input[type="email"],
    .form-col input[type="password"],
    .form-col input[type="tel"] {
        font-size: 16px;
    }

    .form-col input[type="submit"],
    .login-method {
        font-size: 18px;
    }

    .form-link {
        font-size: 17px;
        padding-top: 45px;
    }
}




 .login-wrapper.user-body .col-md-6:first-child{
    background: #E2F5FF;
 }

  .login-wrapper.user-body .col-md-6:last-child{
    background: #F1FAFF;
 }
 .image-text .btn-primary{
    margin-top: 20px;
    background: rgb(2,148,230);
    background: linear-gradient(180deg, rgba(2,148,230,1) 0%, rgba(16,87,141,1) 100%);
    border: none;
 }

 .image-text .btn-primary:hover{
    background: rgb(2,148,230);
    background: linear-gradient(180deg, rgba(16,87,141,1) 0%, rgba(2,148,230,1) 100%);
 }



 .login-logo-sec{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: inline-block;
 }

 /* Dropdown and label */
 .dropdown-container {
    position: relative;
    margin-top: 20px;
  }
  
  .floating-label {
    position: absolute;
    top: -8px;
    left: 10px;
    background-color: white;
    padding: 0 5px;
    font-size: 12px;
    color: #939395;
    z-index: 1;
  }
  
 .dropdown-container .Dropdown {
    padding-top: 10px;
  }