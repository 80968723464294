@import "../../utils/StylesUtils/variables.scss";

/* Header specific styles */
.page-header {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 15px 0; /* 15px 0;*/
    z-index: 9;
    background-color: #232f3e;

    &.fixed {
        position: fixed;
        top: 0;
        left: 0;
        background-color: #fff;
        padding: 15px 0; /* 15px 0; */
        box-shadow: 0 6px 26px rgba(0, 0, 0, 0.04);
    }

    .logo-public span {
        width: 0;
        display: flex;
    }

    &.fixed .logo-public span {
        width: 100%;
    }

    &.fixed .logo-public img {
        display: none;
    }

    &.fixed .logo-public span img {
        display: inline-block;
    }
}

.header-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;;
}

.logo-public {
    width: 120px;
    margin-top: -20px;

    img {
        width: 100%;
    }
}

.empoHeaderHeading{
    // font-family: BebasNeue;
    font-size: 2.13vw;
    font-weight: bold;
}

.headerDashboard{
    opacity: 0.8;
    font-family: $libreFranklin;
    font-size: $fontSize18pxVw;
    line-height: 0.67;
    text-align: center;
    color: #ffffff;
}

.headerDashboardAccountStyles{
    opacity: 0.8;
    font-size: 20px;
}

.MuiMenu-paper .MuiMenuItem-root{
    color: #656363;
}
.icon-wrap svg{
 font-size: 30px;
}