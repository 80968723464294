.loginButton:hover {
  opacity: 0.9;
}

.desktop-view {
  display: flex;
}

.mobile-view {
  display: none;
}

.specialtieslist-box {
  border: 1px solid #0b5ed7;//#009ff5; 
  border-radius: 15px;
  padding: 20px;
  margin: 20px;
  width: 100%;
  max-width: 600px;
  height: auto;  
  box-sizing: border-box;

  ul {
    list-style-type: disc; /* Use bullets */
    padding-left: 20px; /* Indent the list */
  }

  li {
      margin-bottom: 10px;
      text-align: left;
      font-size: 1em;
  }

  p {
      margin-bottom: 10px;
      text-align: left;
      font-size: 1em; // Adjust font size for mobile
  }
}    
// Media query for tablets and smaller screens
@media (max-width: 768px) {
  .image-text__half.image-text__text {
    order: -1; /* Move the left text to the top */
    margin-top: 25px;
  }
  .specialtieslist-box {
      padding: 15px;
      margin: 10px 0;
      width: calc(100% - 40px); // Adjust width for padding
      
      p {
          font-size: 0.9em;
      }
  }
}

// Media query for mobile screens
@media (max-width: 480px) {
  .image-text__half.image-text__text {
    order: -1; /* Move the left text to the top */
    margin-top: 25px;
  }
  .specialtieslist-box {
      padding: 10px;
      margin: 10px 0;
      width: calc(100% - 20px); // Adjust width for padding
      
      p {
          font-size: 0.8em;
      }
  }
}