@import "../../utils//StylesUtils/variables.scss";

.EditDetailsCompHeadingStyles{
    display: flex;
    justify-content: space-between;

    .editDetailsCompHeading{
        font-family: $libreFranklin;
        font-size: 20px;
        color: #9a9a9a;
    }
}

.EditDetailsCompDetailsStyles{
    display: flex;
    font-family: $libreFranklin;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: #1c1c1c;
    margin-top: 20px;
}

.EditDetailsBottomLineStyles{
    width:100%;
    height:1px;
    background-color:#dedede;
    margin-top:35.5px;
    margin-bottom: 20px;
}

