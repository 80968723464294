@import "../../utils//StylesUtils/variables.scss";

.cardHeader{
    height: 8.22vw;
    border-bottom: 2px solid #c4c4c4;
}

.inviteCardBackArrowStyles{
    color: #c7c7c7;
    float: left;
    margin-left: 1.1vw;
    margin-top: 1.1vw;
}

.inviteCardCrossStyles{
    color: #c7c7c7;
    float: right;
    margin-right: 1.1vw;
    margin-top: 1.1vw;
}

.singleInviteCardProfilePicture{
    width: 100%;
    height: 3.64vw;
    width: 3.64vw;
    border-radius: 50%;
    float: left;
    margin-top: 3.65vw;
    border: 2px solid #ffffff;
    margin-left: -0.7vw;
}

.singleInviteCardProfileName{
    opacity: 0.78;
    font-family: $libreFranklin;
    font-size: $fontSize20pxVw;
    font-weight: 600;
    color: #1c1c1c;
    margin-top: 4.94vw;
    float: left;
    margin-left: 0.73vw;
    width: 10vw;
    height: 2vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.singleInviteFavoriteIcon{
    float: left;
    font-size: 1.2vw;
    margin-top: 4.94vw;
    color: #1876d2;
}

.singleInviteProfileDesignation{
    float: left;
    margin-top: 4.94vw;
    font-family: $libreFranklin;
    font-size: $fontSize20pxVw;
    color: #1c1c1c;
    margin-left: 3.2vw;
}

.singleInviteProfileLocation{
    float: left;
    margin-top: 4.94vw;
    font-family: $libreFranklin;
    font-size: $fontSize20pxVw;
    color: #cab4b4;
    margin-left: 3.2vw;
}

// .inviteCardContent{

// }

.singleInviteDropBoxHeading{
    font-family: $libreFranklin;
    font-size: $fontSize18pxVw;
    font-weight: 600;
    text-align: left;
    color: #707070;
    margin-left: 3vw;
    margin-top: 4vw;
    margin-bottom: 1vw;
}

.TextAreaStyles{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.inviteDetailsCardMain{
    // border-radius: 20px;
    // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    // background-color: #ffffff;
    // width: 68.64vw;
    // height: 42.03vw;
    cursor: auto !important;
}

.inviteCardWorkAddressLabel{
    font-family: $libreFranklin;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: #707070;
}

.singleInviteScheduleLabel{
    font-family: $libreFranklin;
    margin-left: 3vw;
    margin-top: 59px;
    font-size: 20px;
    font-weight: 600;
    color: #707070;
}

.TimePickerStyles{
    border-radius: 10px;
    border: solid 2px #c7c7c7;
    height: 56px;
    width: 12.6vw;
}

.scheduleTable{
    width: 43.54vw;
    height: 379px;
    border-radius: 10px;
    border: solid 2px #c7c7c7;
    margin-top: 45px;
}

.scheduleTableHeadings{
    display: flex;
}

.scheduleTableHeading{
    // font-family: $libreFranklin;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 0.875rem;
    font-weight: bold;
    color: #1c1c1c;
}

.scheduleTableHeadingsBottom{
    height: 2px;
    margin-top: 0.5vw;
    margin-left: 1.5vw;
    padding-right: -1vw;
    width: 93%;
    opacity: 0.78;
    background-color: #707070;
}

.nonPayableTimeLabelForModel {
    font-family: $libreFranklin;
    font-size: 18px;
    color: #707070;
    margin-bottom: 12px;
}

.sceduleListDate{
    opacity: 0.78;
    font-family: $libreFranklin;
    font-size: $fontSize18pxVw;
    font-weight: 600;
    color: #1c1c1c;
    margin-top: 4px;
}

.sceduleListStartTime{
    font-family: $libreFranklin;
    font-size: $fontSize18pxVw;
    color: #1c1c1c;
    margin-top: 4px;
}

.errorMsg{
    color: red;
    font-family: $libreFranklin;
}

.nonPayableTimeLabel{
    font-family: $libreFranklin;
    font-size: 18px;
    color: #707070;
    margin-top: 38px;
    margin-bottom: 25px;
}

.inviteCardScheduleTotal{
    font-family: $libreFranklin;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: #707070;
    margin-top: 18px;
}

.inviteCardScheduleTotalValue{
    width: 95px;
    height: 56px;
    border-radius: 10px;
    border: 2px solid #c4c4c4;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2.5vw;
}