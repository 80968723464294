.ChooseSignInSignUp {
  width: 23.229%;
  height: 39.16%;
  //   background-color: white;
}
.ImagePlaceHolder {
  // width: 100%;
  // height: 100%;
  border-radius: 20px;
  // box-shadow: 9px 5px 13px 0 rgba(0, 0, 0, 0.31);
  display: inline-block;
  // background-color: #fff;
  margin-bottom: 10px;
}
.loginButton:hover {
  opacity: 0.9;
}

.logo-background {
  position: fixed;
  top: 450px;
  left: 0;
  width: 100%;
  height: calc(100% - 450px);
  z-index: 0; /* Position it behind the content and above background*/
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.1; /* Make it subtle */
}

.background-logo {
  width: 50vw; /* Set width to 50% of the viewport width */
  height: auto;
  animation: spinY 60s linear infinite; /* Apply 3D rotation animation */
  transform-style: preserve-3d; /* Ensure 3D effect is preserved */
}

@keyframes spinY {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

.card-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .icon-color {
    color: rgb(168, 168, 168); /* #009FF5 */
  }
  .card-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }
}

.desktop-view {
  display: flex;
}

.mobile-view {
  display: none;
}

@media (max-width: 767px) {
  .desktop-view {
      display: none;
  }

  .mobile-view {
      display: block;
  }
}

.mobile-stepper-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  .MuiMobileStepper-root {
      background: transparent;
  }
}