@mixin line-styles {
  color: #9a9a9a;
  background-color: #cccccc;
  width: 43%;
  display: inline-block;
}

.empoLinkImgStyles {
  display: inline-block;
  background-color: #23286b;
  height: 1080px;
  width: 48.33%;
  color: white;
}

.loginPage {
  display: inline-block;
  position: absolute;
  width: 51.67%;
}

.empolink {
  padding-left: 13px;
  margin-top: 108px;
  margin-bottom: 87px;
  font-family: "Libre Franklin";
  font-size: 38px;
  font-weight: bold;
}

.imgStyles {
  width: 88%;
  margin-left: 58px;
  margin-top: 170px;
}

.googleLogInStyles {
  width: 100%;
  height: 56px;
  border-radius: 10px !important;
  text-decoration: none;
  box-shadow: none !important;
  border: 1px solid #9a9a9a !important;
  text-align: center !important;
}

.loginCard {
  // position: absolute;
  // padding-left: 26.895% !important;
  // padding-right: 26.895% !important;
  // width: 46.21% !important;
}

.loginPageWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
}

.left-line {
  @include line-styles;
}

.right-line {
  @include line-styles;
  margin-left: 3%;
}

.sign-up {
  font-family: "Libre Franklin";
  font-size: 20px;
  color: #9a9a9a;
  text-align: center;
}

.google-sign-in {
  font-family: "Libre Franklin";
  font-size: 20px;
  color: #9a9a9a;
  margin-left: 5px;
  // width: 34vh;
  text-align: center;
}

.rememberMe {
  font-family: "Libre Franklin";
  font-size: 14px;
  color: #9a9a9a;
}

.loginError{
  font-family: 'LIBRE FRANKLIN';
  font-size: 0.89vw;
  color: #b00120;
  margin-top: 15px;
  margin-bottom: 20px;
}

.diffOrStyle{
  margin-left: 3.5%;
  display: inline-block;
  font-size: 20px;
  font-family: 'Libre Franklin';
  color: #9a9a9a;
}
