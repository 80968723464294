@import "../../../utils//StylesUtils/variables.scss";

.myImage {
    width: 100%;
    height: 44.5vw;
    background-image: url("../../../Assets/talentsearch.jpeg");
    background-repeat: no-repeat;
    background-size: 100%;
  }

.searchIcon{
  display: inline-block;
  position: absolute;
  margin-left: 28vw;
  align-items: center;
  font-size: 2.2vw;
  height: 3.8vw;
  width: 5.28vw;
  text-align: center;
  border-radius-right: 10px;
  padding-top: 1vw;
  color:#707070;
  background-color: #ffffff;
  border-radius-right: 10px;
  border-top-right-radius: 1.01vw;
  border-bottom-right-radius: 1.01vw;
}

.searchIcon:hover {
  background-color: #f2f2f2;
  // background-color: #c7c7c7;
}

.searchIcon:active {
  background-color: #1876d2;
  color: #ffffff
}

.hourlyRateWrapper{
  display: inline-block;
  position: absolute;
  margin-left: 6vw;
  padding-left: 4vw;
  /* width: 15vw; */
  height: 5vw;
}

.SearchBarRateShow{
  // font-family: "Libre-Franklin";
  font-size: 0.9375vw;
}

.searchedJob .css-g1d714-ValueContainer {
  padding: 0px !important;
}

.searchedJob .activeCard {
  border: 1px solid rgb(255, 255, 255) !important;
  background-color: rgb(255, 255, 255) !important;
  box-shadow: grey 0px 1px 15px !important;
  border-radius: 12px;
}

.searchedJob .css-c8odyh-control {
  background-color: rgb(255, 255, 255) !important;
}

.searchedJob .hourDropdownLabel {
  font-family: $libreFranklin;
  font-size: $fontSize18pxVw;
  font-weight: 600;
  color: #1c1c1c;
}

.searchedJob .fullHeight {
  height: 100%;
}

.searchedJob .rmdp-container {
  margin-right: 1.4vw;
}

.searchedJob .rmdp-input::placeholder{
  font-size: 0.9375vw;
}

.searchedJob .autoSearchTextBox {
  width: 15vw;
  margin-left: 0.9vw
}

.searchedJob .selectHourPlaceHolder {
  font-size: 0.9375vw;
  color: #707070;
}

.searchedJob .parameterValue {
  font-size: 13px;
  color: #707070;
}

.searchedJob .rmdp-input {
  font-size: 13px;
  color: #707070;
  background-color: "";
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.searchedJob .rmdp-input:focus {
  border: '0px solid black';
  box-shadow: none;
}

.searchedJob #react-select-3-input {
  font-size: 13px;
  color: #707070;
}