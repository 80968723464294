@import "../../utils/StylesUtils/variables.scss";

.footer-public {
    background-color: #232f3e; // Public footer background color
    padding: 40px 0 0 0;
    color: #fff;
    position: relative;
    z-index: 2;

    .top-footer {
        padding-bottom: 40px;
        background-color: inherit;
    }

    .footer-logo {
        width: 120px;
        margin-bottom: 10px;

        img {
            width: 100%;
            display: block;
        }
    }

    .footer-link-wrap {
        display: flex;
        justify-content: space-between;
        width: 50%;
    }
}

.footerOptionsStyle{
    font-family: $libreFranklin;
    font-size: $fontSize16pxVw;
    font-weight: bold;
    line-height: 0.75;
    text-align: center;
    color: #ffffff;
}

.footerEmpolinkStyle{
    opacity: 0.78;
    font-family: "Bebas-Neue";
    font-size: 1.71vw;
    font-weight: bold;
    color: #ffffff;
}

.footerCopyRightStyle{
    opacity: 0.5;
    font-family: SourceSansPro;
    font-size: $fontSize14pxVw;
    font-weight: 600;
    letter-spacing: 0.56px;
    text-align: left;
    color: #ffffff;
}

.link:hover{
    color: #009FF5;
    cursor: pointer;
}
