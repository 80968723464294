@import "../../../utils//StylesUtils/variables.scss";

.verifyEmailHeading {
  font-family: $libreFranklin;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.55;
  letter-spacing: 0.44px;
  color: #333333;
  margin-top: 52.8px;
}

.verifyEmailHeadingWrapper {
  padding-top: 52.8px;
  padding-bottom: 36.5px;
}
.verifyEmailFooterWrapper {
  padding-top: 66px;
  padding-bottom: 108px;
  @media (max-width:1024px) {
    padding-top: 30px;
  }
  .resendOtp {
    color: $themeSecondaryBackgroundColor;
    cursor: pointer;
  }
  .resendOtp:hover {
    text-decoration: underline;
  }
  .resendOtp:active {
    text-decoration: underline;
    opacity: 0.8;
  }
}

.otpHeadline{
  padding-top: 0;
  text-align: center;
}
.otpWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
}
.otpBox{
  padding: 25px 15px;
  border-radius: 7px;
  border: 1px solid rgba(0,0,0,0.2);
  display: block;
  margin: 0 10px;
  
  input{
    outline: 0;
    border: 0;
    width: auto;
    font-size: 20px;
  font-weight: 700;
  }
}
.otpFooter{
  padding-bottom: 0;
  span{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    font-size: 20px;
    @media (max-width:1024px) {
      font-size: 13px;
    }
  }
}
.signUp-btn{

  button{
    @media (max-width:767px) {
      display: block;
      max-width: 280px;
      margin: 0 auto;
    }
  }
}