@import "../../utils//StylesUtils/variables.scss";

.defaultStepperLabelStyles {
    font-family: $libreFranklin;
    font-size: $fontSize20pxVw;
    line-height: 0.75;
    letter-spacing: 0.8px;
    color: #9a9a9a;
    margin-top: 2.16vw;
    height: 3.49vw;

    .defaultStepperInternalHeading {
        margin-left: 40px;
        position: relative;
        top: 50%;
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        display: flex;
        align-items: center;
    }

    .StepperHeader {
        margin-left: 10px;
    }
}

.editDetails {
    display: block;
    .editDetailsContentHeading {
        font-family: $libreFranklin;
        font-size: 36px;
        line-height: 0.81;
        text-align: left;
        color: #070707;
    }
}

.overlayCardTitle {
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    color: #000;
    height: 82.5px;
    border-bottom: 1px solid #c4c4c4;
}

.react-tel-input .flag-dropdown{
    border-right: 1px solid #CACACA;
}

//.react-tel-input .form-control {
//    padding: 18.5px 7px 18.5px 56px;
//}

.react-tel-input .selected-flag .arrow{
    border-top: 4px solid #1876D2!important;
}

.react-tel-input .selected-flag .arrow.up {
    border-top: none!important;
    border-bottom: 4px solid #1876D2!important;
}