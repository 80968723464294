@import "../../utils/StylesUtils/variables.scss";

.empolink-heading {
  color: white;
  position: absolute;
  margin-top: 110px;
  margin-left: 110px;
  font-size: 35px;
  letter-spacing: 2.84px;
  font-family: $libreFranklin;
}

.signUpHeading {
  font-family: $libreFranklin;
  font-size: 38px;
  font-weight: bold;
  margin: 0px;
  padding-top: 111px;
  color: #414141;
}

.signUpSubHeading {
  font-family: $libreFranklin;
  font-size: 26px;
  color: #707070;
  margin-top: 12px;
}

// .custom__switch .MuiFormControlLabel-label {
//   width: 40%;
// }
p {
  margin: 0;
}

.signUp-1 .signUpHeading {
  padding-top: 0;
}

.login-wrapper-step .row-center {
  min-height: 100vh;
}

.login-wrapper-step .container {
  max-width: unset;
}

.login-wrapper-step .col-md-4:first-child {
  background: #E2F5FF;
  // width: 25%;
  // margin-right: 10%;
}

.signUp-btn button {
  font-size: 16px !important;
}

@media (min-width: 768px) {
  .login-right {
    padding-right: 30px;
  }
}

@media (max-width: 767px) {
  .login-wrapper-step .col-md-4:first-child {
    height: 80px;
    padding: 20px 0 !important;
  }

  .sign-up-body.signUp-1 .login-img {
    padding: 0;
  }

  .sign-up-body.signUp-1 .login-img .login-logo {
    position: inherit !important;
    display: block;
    margin: 0 !important;
    background: rgb(226, 245, 255) !important;
  }

  .sign-up-body.signUp-1 .login-img img.signUp-logo {
    display: none;
  }

  .sign-up-body.signUp-1 .signUpHeading {
    padding: 0;
  }

  .sign-up-body.signUp-1 .col-md-4 .login-right {
    padding-top: 0;
  }
}