@import '../src/utils/StylesUtils/variables.scss';

.swal2-container {
  z-index: 10000 !important;
}

body {
    margin: 0;
    padding: 0;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

html,body{
  margin:0;
  padding:0;
}

.content{
    flex: 1;
}

.footer{
    display: inline-block;
    width: 100%;
    background-color: #233975;
}

.needle{
  display: none;
}

th, td {
  font-size: 20px;
}

.table-small-row-font{
  td {
    font-size: 18px;
  }
}

